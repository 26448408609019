import {
  AuthChangePasswordData,
  AuthChangePasswordResponseData,
  ErrorResponse,
} from '@interfaces';
import { useMutation } from '@tanstack/react-query';
import { userAuthApi } from '../../userAuthApi';


interface MutationParams {
  onSuccess: (response: AuthChangePasswordResponseData) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useUserChangePasswordMutation = ({ onSuccess, onError }: MutationParams) => {
  return useMutation({
    mutationFn: async (data: AuthChangePasswordData) =>
      userAuthApi.client.userChangePassword(data),
    onSuccess,
    onError,
  });
};

export default useUserChangePasswordMutation;
