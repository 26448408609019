import React, { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import useHasUnreadNotificationsQuery from '@api/forum/queries/notifications/useHasUnreadNotificationsQuery';

import { BellIcon } from '@shared/assets';
import { useOutsideClick } from '@shared/hooks';

import Notifications from '../notifications/Notifications';

interface IProps {
  isPublisherNav?: boolean;
}

const NotificationsButton = ({ isPublisherNav }: IProps) => {
  const [isNotificationsListOpen, setIsNotificationsListOpen] = useState(false);
  const { unreadNotificationsStatus } = useHasUnreadNotificationsQuery();

  const notificationsRef = useOutsideClick(() =>
    setIsNotificationsListOpen(false)
  );

  return (
    <>
      <div className="size-16" ref={notificationsRef}>
        <button
          className={twMerge(
            'customTransition relative flex h-full w-full items-center justify-center border-l border-r border-neutral700 text-neutral400 hover:text-white',
            isPublisherNav && 'border-r-0'
          )}
          onClick={() => setIsNotificationsListOpen((prev) => !prev)}
        >
          <BellIcon className="size-6" />
          <div
            className={twMerge(
              'absolute right-[19px] top-[16.5px] hidden size-2.5 rounded-full bg-green500',
              unreadNotificationsStatus.status && 'flex'
            )}
          />
        </button>
        <Notifications
          isNotificationsListOpen={isNotificationsListOpen}
          setIsNotificationsListOpen={setIsNotificationsListOpen}
          unreadNotificationsStatus={unreadNotificationsStatus.status}
        />
      </div>
    </>
  );
};

export default NotificationsButton;
