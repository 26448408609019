import useUserChangePasswordMutation from "@api/user/auth/mutations/change-password/useUserChangePasswordMutation";
import ChangePasswordModal from "@components/modals/changePasswordModal";
import { ChangePasswordFormData } from "@shared/schemas";
import { toastError, toastSuccess } from "@shared/utils";

interface ChangePasswordModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export const UserChangePassword = ({ isModalOpen, setIsModalOpen }: ChangePasswordModalProps) => {

  const { mutate: userChangePasswordMutation, isPending: isLoading } =
    useUserChangePasswordMutation({
      onSuccess: () => {
        toastSuccess('Changes saved successfully')
        setIsModalOpen(false);
      },
      onError: (e) => {
        if (e.message.includes("Incorrect password. Please try again")) {
          toastError("Incorrect password. Please try again");
        } else {
          toastError(e.message);
        }
      },
    });

  const onSubmitChangePassword = (data: ChangePasswordFormData) => {
    userChangePasswordMutation({
      currentPassword: data.oldPassword,
      password: data.newPassword,
    });
  }

  return (
    <ChangePasswordModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onSubmit={onSubmitChangePassword}
      isLoading={isLoading}
    />
  )
}